import React from 'react';

import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import Layout from '../components/Layout';

import Card from '../components/Card';

import pic1 from '../assets/images/actors/noblet.jpg';

const EventPage = () => (
  <Layout fullMenu>
    <section id="wrapper">
      <header>
        <div className="inner">
          <h2>Les artistes</h2>
          <p>Des artistes à part.</p>
        </div>
      </header>

      <div className="wrapper">
        <div className="inner">
          <h3 className="major">Les Artistes associés</h3>
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar
            <b><a href="/creations">les creations en mouvement</a></b>
  iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet. Sed non laoreet justo. Sed sit amet gravida orci. Quisque vel nibh dolor. Quisque mattis sed enim eu congue
            <b><a href="/concerts">les concerts</a></b>
  . Mauris et pulvinar purus. Quisque et nunc porttitor libero tempor faucibus. Nam laoreet ullamcorper lacus eu tincidunt. In iaculis sagittis arcu eu consectetur. Pellentesque sit amet libero et tortor sagittis ultricies quis ut lorem. </p>

          <section className="features">
            <Card type='artiste' path="/artiste" title="Mickael Gat" image="1" tagline="em ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." statut="Musicien, compositeur, danseur" place="New York" ig="mickael"/>
            <Card type='artiste' path="/artiste" title="Mickael Gat" image="1" tagline="em ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." statut="Musicien, compositeur, danseur" place="New York"/>
            <Card type='artiste' path="/artiste" title="Mickael Gat" image="1" tagline="em ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." statut="Musicien, compositeur, danseur" place="New York"/>
            <Card type='artiste' path="/artiste" title="Mickael Gat" image="1" tagline="em ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." statut="Musicien, compositeur, danseur" place="New York"/>
            <Card type='artiste' path="/artiste" title="Mickael Gat" image="1" tagline="em ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." statut="Musicien, compositeur, danseur" place="New York"/>
            <Card type='artiste' path="/artiste" title="Mickael Gat" image="1" tagline="em ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." statut="Musicien, compositeur, danseur" place="New York"/>
            <Card type='artiste' path="/artiste" title="Mickael Gat" image="1" tagline="em ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." statut="Musicien, compositeur, danseur" place="New York"/>
            <Card type='artiste' path="/artiste" title="Mickael Gat" image="1" tagline="em ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." statut="Musicien, compositeur, danseur" place="New York"/>
            <Card type='artiste' path="/artiste" title="Mickael Gat" image="1" tagline="em ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." statut="Musicien, compositeur, danseur" place="New York"/>
            
            <Card type='artiste' path="/artiste" title="Mickael Gat" image="1" tagline="em ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." statut="Musicien, compositeur, danseur" place="New York"/>

            <Card type='artiste' path="/artiste" title="Mickael Gat" image="1" tagline="em ipsum dolor sit amet, consectetur adipiscing elit. Vivamus pulvinar iaculis tellus ut rhoncus. Etiam tempor laoreet aliquet." statut="Musicien, compositeur, danseur" place="New York"/>

          </section>
        </div>
      </div>
    </section>
  </Layout>
);

export default EventPage;
